import * as React from 'react';
import {IHostProps, ISantaProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IPropsInjectedByViewerScript} from '../types/app-types';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: GlobalProps;
}

export type GlobalProps = Pick<
  IPropsInjectedByViewerScript & ISantaProps,
  // eslint-disable-next-line @typescript-eslint/tslint/config
  'shouldDisableButton' | 'buttonText' | 'handleAddToCart' | 'onAppLoaded' | 'addedToCartStatus'
>;

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    const {
      buttonText,
      shouldDisableButton,
      handleAddToCart,
      addedToCartStatus,
      onAppLoaded,
      ...passThroughProps
    } = props;
    return (
      <PropsContext.Provider
        value={{
          globals: {
            buttonText,
            shouldDisableButton,
            addedToCartStatus,
            handleAddToCart,
            onAppLoaded,
          },
        }}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return (props) => {
    return <PropsContext.Consumer>{(globalProps) => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}
